<template>
  <div class="container">
    <div>
      <vs-button @click="showNewPatient = true"> Nuevo Paciente </vs-button>
    </div>
    <h2>Mis Pacientes</h2>
    <vs-table>
      <template #thead>
        <vs-input v-model="searchName" border placeholder="Nombre Completo" @change="searchName2= searchName==''?'':searchName;searchMail=''; searchNIF='' " @blur="searchName2= searchName==''?'':searchName;searchMail=''; searchNIF='' " />
        <vs-input v-model="searchMail" border placeholder="Email" @change="searchName=''; searchNIF='' " />
        <vs-input v-model="searchNIF" border placeholder="DNI/NIE/Pass" @change="searchName=''; searchMail='' " />
        <vs-button>Buscar</vs-button>
        <vs-tr>
          <vs-th> Nombre del paciente </vs-th>
        </vs-tr>
      </template>
      <template #tbody>
        <vs-tr ref="expandInfo" :key="i" v-for="(tr, i) in $vs.getSearch($vs.getSearch($vs.getSearch(patients,searchNIF),searchMail),searchName2)" :data="tr">
          <vs-td>
            {{ tr.displayName }}
          </vs-td>
          <template #expand>
            <div class="con-content">
              <vs-row class="userData">
                <vs-col :w="2">
                  <vs-avatar>
                    <img
                      :src="
                        tr.profileImg ||
                        `https://www.coprivado.com.ar/panel_control/files/novedades/194_avatar.png`
                      "
                      alt=""
                    />
                  </vs-avatar>
                </vs-col>
                <vs-col :w="4">
                  <div class="userDataItem">
                    {{ tr.displayName }}
                  </div>
                </vs-col>
                <vs-col :w="2">
                  <div class="userDataItem">
                    {{ tr.birthDate ? getYears(tr.birthDate) + " años" : null }}
                  </div>
                </vs-col>
                <vs-col :w="1">
                  <div
                    v-if="tr.gender === 1 || tr.gender === true"
                    class="userDataItem"
                  >
                    <i class="fas fa-male"></i>
                  </div>
                  <div
                    v-else-if="tr.displayName !== 'Usuarios no registrados'"
                    class="userDataItem"
                  >
                    <i class="fas fa-female"></i>
                  </div>
                </vs-col>
                <!-- <vs-col :w="3">
                  <div class="userDataItem">
                    <small>
                      <b>Última cirujía:</b> {{ tr.lastOperation }}
                    </small>
                  </div>
                </vs-col> -->
              </vs-row>
              <vs-row>
                <b>Intervenciones:</b>
              </vs-row>
              <vs-row
                class="userData"
                v-for="(intervention, ind) in tr.interventions"
                :key="'intervention' + ind"
              >
                <div style="width: 90%">
                  {{ intervention.name }} - (
                  <small
                    @click="
                      openCalendar(
                        intervention.surgeryDate,
                        tr.id,
                        intervention.id
                      )
                    "
                    style="cursor: pointer; text-decoration: underline"
                    >{{ intervention.surgeryDate }}</small
                  >) <small> {{ intervention.contact }}</small>
                </div>
                <div style="width: 10%; display: flex">
                  <vs-button
                    dark
                    floating
                    icon
                    @click="printCode(intervention.id)"
                  >
                    <i class="fas fa-print"></i>
                  </vs-button>
                  <vs-button
                    dark
                    floating
                    v-if="intervention.contact || tr.email"
                    icon
                    @click="
                      sendCode(
                        intervention.id,
                        intervention.contact || tr.email
                      )
                    "
                  >
                    <i class="fas fa-envelope"></i>
                  </vs-button>
                </div>
                <vs-col
                  :w="12 / intervention.results.length"
                  v-for="(result, ind) in intervention.results"
                  :key="'result' + ind"
                >
                  <div v-if="result">
                    <vs-button
                      flat
                      :success="result.result_id !== null && result.result_dr_id !== null"
                      :active="result.status > 2"
                      :disabled="currentDate < result.activateDay"
                      :danger="currentDate < result.activateDay"
                      @click="
                        openProtocol(
                          intervention.protocol,
                          result,
                          intervention,
                          i,
                          tr
                        )
                      "
                    >
                      {{ result.name }}
                    </vs-button>
                  </div>
                </vs-col>
                  <div v-if="intervention.addHojas" style="width:100%">
                  <vs-button @click="showHojaQ = true; selectedProtocol = intervention; currentPatient = tr.id; onSelectHojas(intervention)" color="rgb(59,222,200)" gradient block size="mini" >Hoja Quirúrgica</vs-button>
                </div>
              </vs-row>
              <div class="userActions">
                <vs-button flat icon @click="showPatientProfile(tr.id)">
                  <!-- <i class="fas fa-user-edit"></i> -->
                  <i class="fas fa-id-card-alt"></i>
                </vs-button>
                <vs-button flat icon> Contactar </vs-button>
                <vs-button border danger> Eliminar </vs-button>
              </div>
            </div>
          </template>
        </vs-tr>
      </template>
      <template #notFound> No hay ningún paciente con estas características </template>
    </vs-table>

    <vs-dialog v-model="showCalendar">
      <template #header>
        <h4 class="not-margin">
          <b> Fecha de la intervención : </b>
        </h4>
      </template>
      <br />
      <vs-input type="date" v-model="selectedEntity.date"> </vs-input>

      <template #footer>
        <div class="footer-dialog">
          <vs-button block success @click="onUpdateSurgeryDate">
            Actualizar
          </vs-button>
        </div>
      </template>
    </vs-dialog>

    <vs-dialog v-model="showPatient">
      <template #header> Datos del paciente: </template>
      <div style="margin-top: 40px">
        <h4>Resumen de Intervenciones</h4>
        <div style="margin-top: 40px">
          <vs-select
            label="Intervención"
            placeholder="Selecciona Intervención"
            v-model="selectedIntervention"
            @change="loadChart"
          >
            <vs-option
              v-for="(inter, i) in interventions"
              :key="'options_' + i"
              :label="inter.name"
              :value="inter.id"
            >
              {{ inter.name }}
            </vs-option>
          </vs-select>
          <br />
          <h6 v-if="selectedIntervention !== ''">Resultados</h6>
          <vs-table v-if="selectedIntervention !== ''">
            <template #thead>
              <vs-tr>
                <vs-th> Intervención </vs-th>
                <vs-th> Fecha </vs-th>

                <vs-th
                  v-for="(res, i) in filteredIntervention[0].results"
                  :key="'th_' + i"
                >
                  {{ res.name }}
                </vs-th>
              </vs-tr>
            </template>
            <template #tbody>
              <vs-tr
                :key="i"
                v-for="(tr, i) in filteredIntervention"
                :data="tr"
              >
                <vs-td>
                  {{ tr.name }}
                </vs-td>
                <vs-td>
                  {{ tr.surgeryDate }}
                </vs-td>
                <vs-td
                  v-for="(result, ind) in tr.results"
                  :key="'tr_' + i + '_' + ind"
                >
                  {{
                    result.points_dr
                      ? result.points + result.points_dr
                      : result.points
                  }}
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
          <line-chart
            v-if="loaded && selectedIntervention !== ''"
            :chartData="datacollection"
            :options="this.options"
          />
          <br />
        </div>
      </div>
    </vs-dialog>

    <vs-dialog v-model="editActive">
      <template #header> Change Prop {{ editProp }} </template>
      <vs-input
        @keypress.enter="editActive = false"
        v-if="editProp == 'email'"
        v-model="edit[editProp]"
      />
      <vs-select
        @change="editActive = false"
        block
        v-if="editProp == 'name'"
        placeholder="Select"
        v-model="edit[editProp]"
      >
        <vs-option label="Vuesax" value="Vuesax"> Vuesax </vs-option>
        <vs-option label="Vue" value="Vuejs"> Vue </vs-option>
        <vs-option label="Javascript" value="Javascript">
          Javascript
        </vs-option>
        <vs-option disabled label="Sass" value="Sass"> Sass </vs-option>
        <vs-option label="Typescript" value="Typescript">
          Typescript
        </vs-option>
        <vs-option label="Webpack" value="Webpack"> Webpack </vs-option>
        <vs-option label="Nodejs" value="Nodejs"> Nodejs </vs-option>
      </vs-select>
    </vs-dialog>

    <vs-dialog v-model="showNewPatient" blur>
      <template #header>
        <h5 class="not-margin">Añadir nuevo <b>Paciente</b></h5>
      </template>

      <div class="con-form">
        <vs-input
          v-model="interventionName"
          label="Nombre de la intervención"
          placeholder="Lo que verá el cliente:"
          class="inputLogin"
        >
          <template #icon>
            <i class="fas fa-heartbeat"></i>
          </template>
          <template v-if="interventionName === ''" #message-danger>
            Required
          </template>
        </vs-input>

        <vs-input
          class="inputLogin"
          type="date"
          v-model="surgeryDate"
          label="Fecha de cirugía:"
        />

        <i class="fas fa-notes-medical"></i>
        <vs-select
          filter
          label-placeholder="Selecciona el protocolo"
          v-model="protocol"
          class="selectInput"
        >
          <vs-option
            v-for="(prot, ind) in protocolos"
            :key="'protocolo_' + ind"
            :label="prot.name"
            :value="prot.id"
          >
            {{ prot.name }}
          </vs-option>
          <template v-if="protocol === ''" #message-danger> Required </template>
        </vs-select>

        <i class="fas fa-user"></i>
        <vs-select
          filter
          label-placeholder="Selecciona el paciente"
          v-model="patient"
          class="selectInput"
        >
          <vs-option-group>
            <vs-option label="Nuevo paciente" value="wCWNQrCfmF9wGHiKI3IJ">
              Nuevo paciente
            </vs-option>
          </vs-option-group>
          <vs-option-group>
            <div slot="title">Pacientes registrados</div>
            <vs-option
              v-for="(pat, ind) in users"
              :key="'patient_' + ind"
              :label="pat.displayName"
              :value="pat.id"
            >
              {{ pat.displayName }}
            </vs-option>
          </vs-option-group>
          <template v-if="patient === ''" #message-danger> Required </template>
        </vs-select>

        <vs-input
          v-if="patient === 'wCWNQrCfmF9wGHiKI3IJ'"
          label-placeholder="Introduce el mail de contacto"
          v-model="mailToSend"
          type="email"
          class="inputLogin"
        >
        </vs-input>

        <i class="fas fa-users"></i>
        <vs-select
          filter
          label-placeholder="Selecciona el grupo"
          v-model="group"
          class="selectInput"
        >
          <vs-option
            v-for="(grupo, ind) in groups"
            :key="'grupo_' + ind"
            :label="grupo.name"
            :value="grupo.id"
          >
            {{ grupo.name }}
          </vs-option>
          <template v-if="group === ''" #message-danger> Required </template>
        </vs-select>

        <i class="fas fa-file-medical-alt"></i>
        <vs-input
          filter
          label-placeholder="Tipo de cirugía"
          v-model="surgeryType"
          class="inputLogin"
        >
          <!-- <vs-option
            v-for="(surgeries, ind) in surgeryTypes"
            :key="'surgeries_' + ind"
            :label="surgeries.name"
            :value="surgeries.name"
          >
            {{ surgeries.name }}
          </vs-option> -->
          <template v-if="surgeryType === ''" #message-danger>
            Required
          </template>
        </vs-input>

        <!-- <b>Periodicidad:</b>
        <vs-button-group class="multiSelectBtns">
          <vs-button
            flat
            v-for="(period, ind) in periods"
            :key="'period_' + ind"
            :active="period.status"
            @click="period.status = !period.status"
          >
            {{ period.name }}
          </vs-button>
        </vs-button-group> -->
      </div>

      <template #footer>
        <div class="footer-dialog">
          <vs-button block success @click="onSave"> Generar </vs-button>
        </div>
      </template>
    </vs-dialog>

    <vs-dialog v-model="showCode" blur class="protocolCode">
      <h1>Código de la intervención</h1>
      <h5 style="margin: 1rem auto; text-align: center">{{ protocolCode }}</h5>
      <div style="text-align: start">
        <ol>
          <li>
            Entrar en :
            <a
              href="https://traumareports.com"
              target="_blank"
              rel="noopener noreferrer"
              >TraumaReports</a
            >
          </li>
          <li>Login con tu usuario o registrate</li>
          <li>
            En el menú de la izquierda, click en <b> 'Mis Intervenciones' </b>
          </li>
          <li>Clicar el boton de <b>Nueva intervención</b></li>
          <li>Introducir el código de la intervención</li>
          <li>Aceptar</li>
        </ol>
      </div>
      <template #footer>
        <div class="dialogActionBtns">
          <vs-button @click="printCode(protocolCode)">
            <i class="fas fa-print"></i>
            Imprimir
          </vs-button>

          <vs-input
            state="primary"
            label-placeholder="Email:"
            v-model="mailToSend"
          ></vs-input>
          <vs-button class="mail" @click="sendCode" v-if="mailToSend !== ''">
            <i class="fas fa-envelope"></i>
            Enviar por mail
          </vs-button>
        </div>
        <div style="text-align: center">
          <!-- <small>
        Powered by <a href="http://www.clinikform.com" target="_blank" rel="noopener noreferrer">ClinikForm</a>
        </small>
        <img width="30px" :src="require('@/assets/clinikform.png')" /> -->
        </div>
      </template>
    </vs-dialog>

    <vs-dialog
      width="90%"
      not-center
      not-close
      v-model="showIntervention"
      prevent-close
    >
      <template #header>
        <h4 class="not-margin">
          <b> {{ currentProtocol.name || "Nombre" }} : </b>
        </h4>
        <vs-button
          v-if="showMineAnswers"
          @click="showMineAnswers = false"
          size="mini"
          block
        >
          Ver respuestas del paciente
        </vs-button>
        <vs-button v-else @click="showMineAnswers = true" size="mini" block>
          Ver mis respuestas
        </vs-button>
      </template>

      <div class="con-content" v-if="showMineAnswers">
        <div class="blockStyle" v-if="currentProtocol.addHojas">
          <h4>Hoja Quirúrgica</h4>
          <div class="hojasSelects">
            <vs-select
              filter
              multiple
              class="inputForm"
              :state="hojas.region === '' ? 'warn' : 'success'"
              placeholder="Selecciona la región"
              v-model="hojas.region"
            >
              <vs-option label="Extremidad Inf." value="1">
                Extremidad Inf.
              </vs-option>
              <vs-option label="Extremidad Sup." value="2">
                Extremidad Sup.
              </vs-option>
              <vs-option label="Columna" value="3"> Columna </vs-option>
            </vs-select>

            <vs-select
              v-if="hojas.region"
              filter
              multiple
              class="inputForm"
              :state="hojas.articulacion === '' ? 'warn' : 'success'"
              placeholder="Selecciona la articulación"
              v-model="hojas.articulacion"
            >
              <vs-option label="Cadera" value="1"> Cadera </vs-option>
              <vs-option label="Rodilla" value="2"> Rodilla </vs-option>
              <vs-option label="Pie y tobillo" value="3">
                Pie y tobillo
              </vs-option>
            </vs-select>

            <vs-select
              v-if="hojas.articulacion"
              filter
              multiple
              class="inputForm"
              :state="hojas.type === '' ? 'warn' : 'success'"
              placeholder="Selecciona el tipo de cirugía"
              v-model="hojas.type"
            >
              <vs-option label="Primária" value="1"> Primária </vs-option>
              <vs-option label="Revisión" value="2"> Revisión </vs-option>
              <vs-option label="Artroscópia" value="3"> Artroscópia </vs-option>
              <vs-option label="Fractura" value="4"> Fractura </vs-option>
            </vs-select>

            <vs-select
              v-if="hojas.type"
              filter
              multiple
              class="inputForm"
              :state="hojas.subtype === '' ? 'warn' : 'success'"
              placeholder="Tipo de Artroscópia"
              v-model="hojas.subtype"
            >
              <vs-option label="Ligamento Cruzado Anterior" value="1">
                Ligamento Cruzado Anterior
              </vs-option>
              <vs-option label="Ligamento Cruzado Posterior" value="2">
                Ligamento Cruzado Posterior
              </vs-option>
              <vs-option label="Lesión meniscal" value="3">
                Lesión meniscal
              </vs-option>
              <vs-option label="Lesión Condral" value="4">
                Lesión Condral
              </vs-option>
            </vs-select>

            <vs-select
              v-if="hojas.subtype"
              filter
              multiple
              class="inputForm"
              :state="hojas.lesion === '' ? 'warn' : 'success'"
              placeholder="Lesión"
              v-model="hojas.lesion"
            >
              <vs-option label="Ruptura" value="1"> Ruptura </vs-option>
              <vs-option label="Revisión" value="2"> Revisión </vs-option>
            </vs-select>

            <vs-select
              v-if="hojas.lesion"
              filter
              multiple
              class="inputForm"
              :state="hojas.plastiaType === '' ? 'warn' : 'success'"
              placeholder="Tipo de Artroscópia"
              v-model="hojas.plastiaType"
            >
              <vs-option label="Isquiotibiales" value="1">
                Isquiotibiales
              </vs-option>
              <vs-option label="Tendón rotuliano" value="2">
                Tendón rotuliano
              </vs-option>
              <vs-option label="Aloinjerto" value="3"> Aloinjerto </vs-option>
              <vs-option label="Otro" value="4"> Otro </vs-option>
            </vs-select>

            <vs-select
              v-if="hojas.plastiaType"
              filter
              multiple
              class="inputForm"
              :state="hojas.fix1 === '' ? 'warn' : 'success'"
              placeholder="Fijación proximal"
              v-model="hojas.fix1"
            >
              <vs-option label="Tornillo interferencial" value="1">
                Tornillo interferencial
              </vs-option>
              <vs-option label="Sistema de suspensión" value="2">
                Sistema de suspensión
              </vs-option>
            </vs-select>

            <vs-select
              v-if="hojas.plastiaType"
              filter
              multiple
              class="inputForm"
              :state="hojas.fix2 === '' ? 'warn' : 'success'"
              placeholder="Fijación distal"
              v-model="hojas.fix2"
            >
              <vs-option label="Tornillo interferencial" value="1">
                Tornillo interferencial
              </vs-option>
              <vs-option label="Sistema de suspensión" value="2">
                Sistema de suspensión
              </vs-option>
            </vs-select>
          </div>
        </div>

        <div class="blockStyle">
          <h4>Información básica</h4>
          <div v-if="!hasSomeBasic">
            <small> No hay que rellenar ninguna información básica </small>
          </div>
          <vs-input
            v-if="
              currentProtocol.basics &&
              (currentProtocol.basics.addDiagnotic ||
                currentProtocol.basics.diagnostic)
            "
            class="inputForm"
            v-model="answers.basics.diagnostic"
            label-placeholder="Diagnostico:"
            type="text"
          >
            <template v-if="answers.basics.diagnostic === ''" #message-danger>
              Requerido
            </template>
          </vs-input>
          <vs-input
            v-if="
              currentProtocol.basics &&
              (currentProtocol.basics.addTratamiento ||
                currentProtocol.basics.tratamiento)
            "
            class="inputForm"
            v-model="answers.basics.tratamiento"
            label-placeholder="Tratamiento:"
            type="text"
          >
            <template v-if="answers.basics.tratamiento === ''" #message-danger>
              Requerido
            </template>
          </vs-input>
        </div>

        <div class="blockStyle">
          <h4>Preguntas personalizadas</h4>
          <div
            v-if="
              !currentProtocol.questions ||
              currentProtocol.questions.length === 0
            "
          >
            <small
              >No hay preguntas personalizadas para este cuestionario</small
            >
          </div>
          <div
            v-for="(question, ind) in currentProtocol.questions"
            :key="'customans_' + ind"
          >
            <vs-input
              v-if="answers.custom[ind].destinyType !== '1'"
              class="inputForm"
              v-model="answers.custom[ind].answer"
              :label="question.text"
              placeholder="Respuesta:"
            />
          </div>
        </div>

        <!-- <div class="blockStyle">
          <h4>Protocolos estándart</h4>
          <div v-if="!hasSomeStandard">
            <small>
              No hay que rellenar ningún protocolo estándart
            </small>
          </div>
          <div
            class="blockStyle"
            v-if="
              currentProtocol.standards &&
                currentProtocol.standards.addAkss.status
            "
          >
            AKSS
          </div>
          <div
            class="blockStyle"
            v-if="
              currentProtocol.standards &&
                currentProtocol.standards.addEva.status
            "
          >
            EVA
          </div>
          <div
            class="blockStyle"
            v-if="
              currentProtocol.standards &&
                currentProtocol.standards.addKnee.status
            "
          >
            <b> {{ standardProtocols[0].form.name || "-" }}</b>

            <div
              v-for="(chapter, ind) in standardProtocols[0].form.chapters"
              :key="'chapter_' + ind"
            >
              <div class="blockStyle" v-if="chapter.forDoctor === true">
                <b>{{ chapter.name || "-" }}</b>
                <br />
                <div
                  style="text-align:start"
                  v-for="(field, ind2) in chapter.fields"
                  :key="'field_' + ind2"
                >
                  <div class="blockStyle" v-if="field.forDoctor">
                    <h6>{{ field.title || "" }}</h6>
                    <vs-input
                      v-if="['text', 'number', 'date'].includes(field.type)"
                      :type="field.type || 'text'"
                      class="inputForm"
                      :label="field.name"
                      v-model="
                        answers.standards[0].form.chapters[ind].fields[ind2]
                          .answer
                      "
                    />
                    <vs-select
                      class="inputForm"
                      v-else-if="field.type === 'dropdown'"
                      :label="field.name"
                      v-model="field.answer"
                    >
                      <vs-option
                        v-for="(option, ind) in field.options"
                        :key="'option_' + ind"
                        :label="option.text"
                        :value="option.value"
                      >
                        {{ option.text }}
                      </vs-option>
                    </vs-select>
                  </div>
                </div>
              </div>
            </div>
            <small>{{ standardProtocols[0].form.copyright }}</small>
          </div>
          <div
            class="blockStyle"
            v-if="
              currentProtocol.standards &&
                currentProtocol.standards.addOxford.status
            "
          >
            OXFORD
          </div>
          <div
            class="blockStyle"
            v-if="
              currentProtocol.standards &&
                currentProtocol.standards.addWomac.status
            "
          >
            WOMAC
          </div>
        </div> -->

        <div class="blockStyle">
          <h4>Protocolos personalizados</h4>
          <div v-if="!answers.noStandards || answers.noStandards.length === 0">
            <small
              >No hay protocolos personalizados para este cuestionario</small
            >
          </div>
          <div
            v-for="(noStan, ind2) in answers.noStandards"
            :key="'nonStand_protocol' + ind2"
          >
            <div class="standartProtocol" v-if="noStan.status">
              <div
                v-if="
                  noStan.form.chapters.some((c) =>
                    c.questions.some((q) => q.forDoctor)
                  )
                "
              >
                {{ noStan.name }}
                <div
                  v-for="(chapter, ind) in noStan.form.chapters"
                  :key="'chapter_' + ind"
                  class="standartProtocolCnt"
                >
                  {{ chapter.name }}
                  <div
                    style="text-align: start"
                    v-for="(question, ind2) in chapter.questions"
                    :key="'question_' + ind2"
                  >
                    {{ question.forDoctor ? question.title : null }}
                    <vs-input
                      v-if="
                        question.forDoctor &&
                        ['text', 'number', 'date'].includes(question.type)
                      "
                      :type="question.type || 'text'"
                      class="inputForm"
                      :label="question.name"
                      v-model="question.answer"
                    />
                    <vs-select
                      class="inputForm"
                      v-else-if="
                        question.forDoctor && question.type === 'dropdown'
                      "
                      :label="question.name"
                      v-model="noStan.form.chapters[ind].questions[ind2].value"
                      @change="
                        totalPoints[ind2] = parseInt(
                          noStan.form.chapters[ind].questions[ind2].value
                        )
                      "
                    >
                      <vs-option
                        v-for="(option, ind) in question.options"
                        :key="'option_' + ind"
                        :label="option.name"
                        :value="option.value"
                      >
                        {{ option.name }}
                      </vs-option>
                    </vs-select>
                  </div>
                </div>
              </div>
              <div v-else><small>No hay preguntas para el doctor</small></div>
            </div>
          </div>
        </div>
      </div>

      <div class="con-content" v-else>
        <div v-if="currentUserResult.basics">
          <div class="blockStyle">
            <div>
              <h5>Información básica</h5>
            </div>

            <p v-if="currentUserResult.basics.gender !== ''">
              <b>Genero: </b>
              {{ currentUserResult.basics.gender ? "Masculino" : "Femenino" }}
            </p>
            <p v-if="currentUserResult.basics.years !== ''">
              <b>Edad: </b> {{ currentUserResult.basics.years }}
            </p>
            <p v-if="currentUserResult.basics.diagnostic !== ''">
              <b>Diagnóstico: </b> {{ currentUserResult.basics.diagnostic }}
            </p>
            <p v-if="currentUserResult.basics.tratamiento !== ''">
              <b>Tratamiento: </b> {{ currentUserResult.basics.tratamiento }}
            </p>
            <p v-if="currentUserResult.basics.height !== ''">
              <b>Altura: </b> {{ currentUserResult.basics.height }}
            </p>
            <p v-if="currentUserResult.basics.weight !== ''">
              <b>Peso: </b> {{ currentUserResult.basics.weight }}
            </p>
          </div>

          <div class="blockStyle">
            <div>
              <h5>Preguntas personalizadas</h5>
            </div>
            <div
              v-if="
                !currentUserResult.custom ||
                currentUserResult.custom.length === 0
              "
            >
              <small
                >No hay preguntas personalizadas para este cuestionario</small
              >
            </div>

            <p
              v-for="(custom, ind) in currentUserResult.custom"
              :key="'custom_' + ind"
            >
              <b>{{ custom.text }}</b>
              <br />
              {{ custom.answer || "Sin respuesta" }}
            </p>
          </div>

          <!-- <div class="blockStyle">
            <div>
              <h5>Respuestas de formularios predefinidos</h5>
            </div>
            <div
              v-for="(standard, ind) in currentUserResult.standards"
              :key="'standard_' + ind"
            >
              <h6>{{ standard.name }}</h6>

              <div
                v-for="(chapter, ind) in standard.form.chapters"
                :key="'chapter' + ind"
              >
                <div v-if="!chapter.forDoctor" class="blockStyle">
                  <b style="text-decoration: underline;">{{ chapter.name }}</b>
                  <br />
                  <br />
                  <div
                    v-for="(field, ind) in chapter.fields"
                    :key="'field_' + ind"
                  >
                    {{ field.name }} :
                    <em>{{
                      field.options &&
                      field.options.length > 0 &&
                      typeof field.answer === "number"
                        ? getAnswer(field)
                        : field.answer || "Sin respuesta"
                    }}</em>
                  </div>
                </div>
              </div>
            </div>
          </div> -->

          <div class="blockStyle notebook">
            <div>
              <h5>Respuestas de formularios personalizados</h5>
            </div>
            <div
              v-for="(noStandard, ind) in currentUserResult.noStandards"
              :key="'standard_' + ind"
            >
              <h6>{{ noStandard.name }}</h6>
              <div style="position:relative">
                    <div id="ribbon3">
                    <div>{{ isNaN(getFormPoints(noStandard)) ? '' : getFormPoints(noStandard) }}</div>
                  </div>
                  </div>
              <div
                v-for="(chapter, ind) in noStandard.form.chapters"
                :key="'chapter' + ind"
              >
                <div v-if="!chapter.forDoctor" class="blockStyle">
                  <div style="position:relative">
                    <div id="ribbon2">
                    <div>{{ isNaN(getChapterPoints(chapter)) ? '' : getChapterPoints(chapter) }}</div>
                  </div>
                  </div>
                  <div v-if="chapter.name!=='' || chapter.name">
                  <b style="text-decoration: underline">{{ chapter.name }}</b>
                  <br />
                  <br />
                  </div>
                  <div
                    v-for="(field, ind) in chapter.questions"
                    :key="'field_' + ind"
                  >
                  <div>
{{ field.name }} :
                  </div>
                  <div style="position:relative">
                    <div id="ribbon">
                    <div>{{ (field.answer ? field.answer : field.value) }}</div>
                  </div>
                  <em style="">{{
                      field.options &&
                      field.options.length > 0 &&
                      ["number", "string"].includes(typeof field.value)
                        ? getAnswer(field)
                        :  field.answer ?field.answer: field.value || "Sin respuesta"
                    }}</em>
                  </div>
                    
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <h5>El paciente aún no ha respondido</h5>
        </div>
      </div>

      <template #footer>
        <div class="dialogActionBtns">
          <vs-button @click="showIntervention = false" danger transparent>
            Cancelar
          </vs-button>
          <vs-button
            v-if="showMineAnswers"
            @click="onCompleteForm"
            success
            transparent
          >
            {{ resultStatus ? "Editar" : "Enviar" }}
          </vs-button>
        </div>
      </template>
    </vs-dialog>

    <vs-dialog
      width="90%"
      not-center
      not-close
      v-model="showHojaQ"
      prevent-close
    >
      <template #header>
        <h4 class="not-margin">
          <b> {{ "Hoja Quirúrgica" }} : </b>
        </h4>
      </template>

      <div>
        <div class="hojasSelects">
            <vs-select
              filter
              class="inputForm"
              v-for="(hoja,ind) in hojas"
              :key="'hojalv_'+ind"
              :state="hoja.value === '' ? 'warn' : 'success'"
              placeholder="Selecciona la región"
              v-model="hoja.value"
              @change="selectHoja(hoja.value.toString())"
            >
              <vs-option v-for="(levelOpt,ind2) in hoja.level"  :key="'levelOpt_'+ind2" :label="levelOpt.name" :value="parseInt(levelOpt.id)">
                {{levelOpt.name}}
              </vs-option>
            </vs-select>
        </div>
      </div>
      <template #footer>
        <div class="dialogActionBtns">
          <vs-button @click="showHojaQ = false" danger transparent>
            Cancelar
          </vs-button>
          <vs-button
            @click="onAddHojaQ"
            success
            transparent
          >
            Enviar
          </vs-button>
        </div>
      </template>
    </vs-dialog>
  </div>
</template>

<script>
import { protocolMix, groupMix, authMix, patientMix, folderMix } from "@/mixins/mixins.js";
import LineChart from "@/components/Charts/line.js";
import axios from "axios";

export default {
  name: "PatientsPage",
  mixins: [protocolMix, groupMix, authMix, patientMix, folderMix],
  components: { LineChart },
  data() {
    return {
      searchName: "",
      searchName2: "",
      searchMail: "",
      searchNIF:"",
      allFolders: [],
      selectedFolders: [],
      selectedLV: [],
      showHojaQ: false,
      editActive: false,
      edit: null,
      editProp: {},
      showMineAnswers: true,
      showIntervention: false,
      showNewPatient: false,
      showCode: false,
      showCalendar: false,
      showPatient: false,
      interventionName: "",
      interventions: [],
      selectedIntervention: "",
      protocolCode: 12421,
      protocol: 0,
      patient: 0,
      group: 0,
      result: 1,
      resultStatus: 2,
      totalPoints: new Array(15).fill(0),
      currentDate: new Date(),
      surgeryType: "",
      mailToSend: "",
      selectedEntity: {},
      surgeryDate: null,
      detailPoints: [],
      currentProtocol: [],
      currentUserResult: {},
      currentPatient: null,
      protocolos: [],
      patients: [],
      users: [],
      groups: [],
      hojas:[],
      periods: [
        {
          name: "Pre Operatorio",
          value: -90,
          status: false,
        },
        {
          name: "15 Días",
          value: 2,
          status: false,
        },
        {
          name: "1 Mes",
          value: 3,
          status: false,
        },
        {
          name: "2 Meses",
          value: 4,
          status: false,
        },
        {
          name: "3 Meses",
          value: 5,
          status: false,
        },
        {
          name: "6 Meses",
          value: 6,
          status: false,
        },
      ],
      surgeryTypes: [
        {
          name: "Operación",
          id: "ij392jf203i",
        },
        {
          name: "Tratamiento",
          id: "ij392j03i",
        },
      ],
      standardProtocols: [],
      answers: {
        basics: {
          gender: false,
          years: "",
          diagnostic: "",
          tratamiento: "",
          height: "",
          weight: "",
        },
        custom: [],
        noStandards: [],
      },
      datacollection: {},
      loaded: false,
      options: {
        responsive: true,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                min: 0, // minimum value
                max: 100, // maximum value
              },
            },
          ],
        },
        maintainAspectRatio: false,
      },
    };
  },
  methods: {

    onSelectHojas(inter){
      if (inter.hojas) {
        this.hojas = inter.hojas;
      }
    },

    async onAddHojaQ(){
      this.selectedProtocol.hojas = this.hojas;
        await this.updateProtocol(
          this.currentPatient,
          this.selectedProtocol.id,
          this.selectedProtocol
        );
       this.$vs.notification({
            color: "success",
            position: "top",
            title: "Añadido con exito",
            text: "Se ha enviado correctamente tus respuestas de la hoja quirúrgica",
      });
      this.showHojaQ = false;
    },

    selectHoja(val){
      if (val !== "") {
        const ind = this.hojas.findIndex((hj)=> hj.value.toString() === val);
        if (ind> -1) {
          this.hojas = ind+1 === this.hojas.length ? this.hojas : this.hojas.slice(ind, ind+1);
        }
        this.selectedLV = val;
      this.selectedFolders = filterByLV(this.allFolders, this.selectedLV);
      if (this.selectedFolders && this.selectedFolders.length > 0) {
        this.hojas.push({value: '', level: this.selectedFolders});
      }
      }
    },

    onUpdateSurgeryDate() {
      const { uid, protocol_id, date } = this.selectedEntity;
      this.updateIntervention(uid, protocol_id, { surgeryDate: date });
    },

    openCalendar(date, uid, protocol_id) {
      this.showCalendar = true;
      this.selectedEntity.date = date;
      this.selectedEntity.uid = uid;
      this.selectedEntity.protocol_id = protocol_id;
    },

    printCode(code) {
      this.showCode = true;
      this.protocolCode = code;
      setTimeout(function () {
        window.print();
        this.showCode = false;
      }, 1000);
    },

    async showPatientProfile(uid) {
      this.interventions = await this.getInterventions(uid);
      this.showPatient = true;
    },

    getYears(birdthDate) {
      const originDate = new Date(birdthDate).getFullYear();
      const today = new Date().getFullYear();
      return today - originDate;
    },

    sendCode(code, mail) {
      let body;
      if (code && mail) {
        body = { code, destiny: mail };
      } else {
        body = {
          code: this.protocolCode,
          destiny: this.mailToSend,
        };
      }
      this.sendEmail(body);
    },

    sendCode_old(code, mail) {
      let body;
      const tham = "traumareports";
      if (code && mail) {
        body = { protocolCode: code, mail, tham };
      } else {
        body = {
          protocolCode: this.protocolCode,
          mail: this.mailToSend,
          tham,
        };
      }

      axios
        .post(
          // "https://o9cwv1e7nj.execute-api.eu-west-1.amazonaws.com/default/trauma_send_intervention",
          "https://us-central1-clinikform.cloudfunctions.net/sendMail",
          body
        )
        .then(() => {
          this.showCode = false;
          this.$vs.notification({
            color: "success",
            position: "top",
            title: "Enviado con exito",
            text: "Se ha enviado el correo electrónico junto con el código de la intervención y las instrucciones con exito",
          });
        })
        .catch((e) => {
          console.log(e);
          this.showCode = false;
          this.$vs.notification({
            color: "danger",
            position: "top",
            title: "Error al enviar",
            text: "Ha habido algún error al enviar el mensaje, contacta con el administrador",
          });
        });
    },

    async onSave() {
      const { currentUserInfo } = this.$store.state;
      const { uid } = currentUserInfo.user
        ? currentUserInfo.user
        : currentUserInfo;
      const {
        patient,
        mailToSend,
        protocol,
        group,
        surgeryType,
        surgeryDate,
        interventionName,
        periods,
      } = this;

      let userr = this.users.filter((us) => us.id === this.patient);
      this.mailToSend = userr[0] ? userr[0].email : this.mailToSend;
      const results = [];
      let currentProtocol = this.protocolos.filter(
        (protocol) => protocol.id == this.protocol
      );
      if (currentProtocol.length > 0) {
        const standardsList = Object.entries(currentProtocol[0].standards).map(
          (e) => ({ [e[0]]: e[1] })
        );
        var activatedStandards = standardsList.filter(
          (standard) => standard[Object.keys(standard)[0]].status
        );
      }

      let activatedNoStandards = currentProtocol[0].noStandards
        ? currentProtocol[0].noStandards.filter((noStan) => noStan.status)
        : [];

      activatedNoStandards.forEach((noStan) => {
        noStan.periods.forEach((period) => {
          let isResultPeriodExists = results.some((res) => res.name == period);
          if (!isResultPeriodExists) {
            let currentPeriod = periods.filter((per) => per.name == period);
            currentPeriod = currentPeriod.length > 0 ? currentPeriod[0] : null;
            if (currentPeriod) {
              const surgeryDay = new Date(
                new Date(surgeryDate).setTime(new Date().getTime())
              );
              const activateDay = surgeryDay.setMinutes(
                surgeryDay.getMinutes() + currentPeriod.value
              );

              results.push({
                name: currentPeriod.name,
                status: 2,
                result_id: null,
                activateDay,
              });
            }
          }
        });
      });

      activatedStandards.forEach((standard) => {
        standard[Object.keys(standard)[0]].periods.forEach((period) => {
          let isResultPeriodExists = results.some((res) => res.name == period);
          if (!isResultPeriodExists) {
            let currentPeriod = periods.filter((per) => per.name == period);
            currentPeriod = currentPeriod.length > 0 ? currentPeriod[0] : null;
            if (currentPeriod) {
              const surgeryDay = new Date(surgeryDate);
              const activateDay = surgeryDay.setDate(
                surgeryDay.getDate() + currentPeriod.value
              );

              results.push({
                name: currentPeriod.name,
                status: 2,
                result_id: null,
                activateDay,
              });
            }
          }
        });
      });

      const data = {
        name: interventionName,
        protocol,
        group,
        addHojas: currentProtocol[0] ? currentProtocol[0].addHojas : false, 
        surgeryType,
        surgeryDate,
        contact: mailToSend || "",
        availableDate: new Date(),
        created_by: uid,
        results,
      };
      await this.assignPatient(uid, { patient });
      const newProtocol = await this.assignProtocol(patient, data);
      this.protocolCode = newProtocol.id;

      this.$vs.notification({
        color: "success",
        position: "top",
        title: "Añadido con exito",
        text: "Se ha añadido la intervención al paciente con exito",
      });

      const scheduleURL =
        "http://ec2-52-214-183-73.eu-west-1.compute.amazonaws.com:5000/api/schedule";

      const scheduleBody = {
        userId: patient,
      };
      axios.post(scheduleURL, scheduleBody);

      if (patient === "wCWNQrCfmF9wGHiKI3IJ") {
        this.showNewPatient = false;
        this.showCode = true;
      } else {
        this.patients = [];
        this.loadUsers(uid);
        this.showCode = true;
        this.showNewPatient = false;
      }
    },

    async loadProtocols(uid) {
      this.protocolos = await this.getProtocols(uid);
      this.standardProtocols = await this.getStandardProtocols();
      this.answers.standards = this.standardProtocols;
    },

    async loadGroups(uid) {
      this.groups = await this.getGroups(uid);
    },

    async loadUsers(uid) {
      this.users = await this.getAllUsers();
      this.patients = await this.getAllPatients(uid);
      this.users = this.users.filter((a) => !a.isDoctor);
    },

    async loadFolders() {
      const folders =   await this.getFolders();
      this.selectedLV = '0';
      this.allFolders = folders;
      this.selectedFolders = filterByLV(folders, this.selectedLV);
      this.hojas = [{value: '', level: this.selectedFolders}];
    },

    async openProtocol(protocol_id, result, selectedProtocol, ind, patient) {
      const { expandInfo } = this.$refs;
      expandInfo[ind].$el.click();
      this.currentPatient = patient.id;
      this.selectedProtocol = selectedProtocol;
      this.currentProtocolName = result.name;
      this.resultStatus = result.result_dr_id ? true : false;
      if (result.status > 2) {
        const defaultProtocol = await this.getProtocol(protocol_id);
        this.currentProtocol = defaultProtocol[0];
        const resultProtocol = result.result_dr_id
          ? await this.getResult(result.result_dr_id)
          : defaultProtocol[0];
        this.currentProtocol.noStandards = resultProtocol.noStandards;
        this.currentProtocol.basics = resultProtocol.basics;
        this.answers = this.currentProtocol;
      } else {
        const defaultProtocol = await this.getProtocol(protocol_id);
        this.currentProtocol = defaultProtocol[0];
        this.answers.custom = this.currentProtocol.questions;
      }

      // if (result.status === 2) {
      this.currentUserResult = result.result_id
        ? await this.getResult(result.result_id)
        : {};
      this.showIntervention = true;
      // }
    },

    // calculatePoints(question) {
    //   const { answer, points, name } = question;
    //   if (!points || isNaN(answer)) {
    //     return;
    //   }
    //   const checked = this.detailPoints.some((detail) => detail.name === name);

    //   if (checked) {
    //     const ind = this.detailPoints.findIndex(
    //       (detail) => detail.name === name
    //     );
    //     if (ind > -1) {
    //       this.sumPoints -= this.detailPoints[ind].value;
    //       this.detailPoints.splice(ind, 1);
    //     }
    //   }
    //   const answerValue = parseInt(answer, 10);
    //   let sumPoints = this.sumPoints;

    //   points.forEach((point) => {
    //     if (point.min <= answerValue && point.max >= answerValue) {
    //       sumPoints += point.value;
    //       this.detailPoints.push({
    //         name,
    //         value: point.value,
    //       });
    //     }
    //   });

    //   this.sumPoints = sumPoints;
    // },

    async onCompleteForm() {
      if (typeof(this.answers.noStandards) === "undefined") {
        this.answers.noStandards = [];
      }
      const data = this.answers;
      const ind = this.selectedProtocol.results.findIndex(
        (res) => res.name === this.currentProtocolName
      );

      

      if (ind > -1) {
        // const { uid } = this.$store.state.currentUserInfo.user ? this.$store.state.currentUserInfo.user : this.$store.state.currentUserInfo;
        const result_id = await this.addResult(data);
        // this.selectedProtocol.results[ind].hojas = this.hojas;
        this.selectedProtocol.results[ind].result_dr_id = result_id.id;
        this.selectedProtocol.results[ind].points_dr = this.totalPoints.reduce(
          (pSum, a) => pSum + a,
          0
        );
        this.selectedProtocol.results[ind].status += 1;
        // const results = this.selectedProtocol.results;
        await this.updateProtocol(
          this.currentPatient,
          this.selectedProtocol.id,
          this.selectedProtocol
        );
      }
      this.showIntervention = false;
    },

    async initData() {
      const loading = this.$vs.loading({
        color: "#fff",
        background: "#17a2b8",
      });
      const { currentUserInfo } = this.$store.state;

      const { uid } = currentUserInfo.user
        ? currentUserInfo.user
        : this.$store.state.currentUserInfo;
      await this.loadProtocols(uid);
      await this.loadGroups(uid);
      await this.loadUsers(uid);
      await this.loadFolders();
      loading.close();
    },

    async loadChart() {

      await this.fillData();
      const data = Object.assign({}, this.datacollection);

      const currentInterv = this.interventions.filter(
        (inter) => inter.id === this.selectedIntervention
      )[0];

      // const defaultProtocol = await this.getProtocol(currentInterv.protocol);
      //   this.currentProtocol = defaultProtocol[0];
      //   const resultProtocol = currentInterv.result[0].result_dr_id
      //     ? await this.getResult(currentInterv.result[0].result_dr_id)
      //     : defaultProtocol[0];
      let labels = [];
        // points = [];
      currentInterv.results.forEach((res) => {
        labels.push(res.name);
        // if (res.points) {
        //   let pts = res.points_dr ? res.points + res.points_dr : res.points;
        //   points.push(pts);
        // } else {
        //   let pts = res.points_dr ? 0 + res.points_dr : 0;
        //   points.push(pts);
        // }
      });
      data.labels = labels;
      this.datacollection = data;
      this.loaded = true;
    },

    async fillData() {
      const currentInterv = this.interventions.filter(
        (inter) => inter.id === this.selectedIntervention
      )[0];
      if(currentInterv){
        const defaultProtocol = await this.getProtocol(currentInterv.protocol);
        this.currentProtocol = defaultProtocol[0];
        const datasets = [];
        let pos = 0;

        for await (const noStd of this.currentProtocol.noStandards) {
          if (noStd.status) {
            datasets.push({
                    label: noStd.name,
                    backgroundColor: '#'+(Math.random() * 0xFFFFFF << 0).toString(16).padStart(6, '0')+'99',
                    data: await this.getFormLayersPoints(currentInterv,pos),
            });
            pos+=1;
          }
        }

        this.datacollection = {
          labels: [],
          datasets,
        }
      }else{
        this.datacollection = {
        labels: [],
        datasets: [
          {
            label: "Evolución",
            backgroundColor: "#17a2b8aa",
            data: [],
          },
          {
            label: "Evolución 2",
            backgroundColor: "#00a2b8aa",
            data: [],
          },
        ],
      };
      }

      
    },

    async getFormLayersPoints(currentInterv,pos){
      const points=[];
      for await (const result of currentInterv.results) {
        if(result.result_id){
          let noStdResult = await this.getResult(result.result_id);
          const aa = this.getFormPoints(noStdResult.noStandards[pos]);
          points.push(aa);
        }else{
          points.push(0);
        }
      }
      return points;
    },

    getFormPoints(noStan){
      var points = 0;
        noStan.form.chapters.forEach((chapter)=>{
          points += this.getChapterPoints(chapter)
        });
      return points;
    },

    getChapterPoints(chapter){
      var points = 0;
      chapter.questions.forEach((field)=>{
        points += field.answer ? parseInt(field.answer) : parseInt(field.value)
      });
      return points;
    },
  },
  computed: {
    getAnswer() {
      return (field) => {
        const filteredOp = field.options.filter(
          (op) => op.value === field.value
        );

        if (filteredOp.length > 0) {
          return filteredOp[0].name;
        } else {
          return field.options[field.value];
        }
      };
    },
    hasSomeBasic() {
      const { currentProtocol } = this;
      let diagnostic =
        currentProtocol.basics &&
        (currentProtocol.basics.addDiagnotic ||
          currentProtocol.basics.diagnostic);
      let tratamiento =
        currentProtocol.basics &&
        (currentProtocol.basics.addTratamiento ||
          currentProtocol.basics.tratamiento);
      return diagnostic && tratamiento;
    },

    hasSomeStandard() {
      const { currentProtocol } = this;
      const { standards } = currentProtocol;
      if (standards) {
        var kss = standards.addAkss.status;
        var eva = standards.addEva.status;
        var knee = standards.addKnee.status;
        var oxford = standards.addOxford.status;
        var womac = standards.addWomac.status;
      }

      return standards && kss && eva && womac && knee && oxford;
    },

    filteredIntervention() {
      return this.interventions.filter(
        (inter) => inter.id === this.selectedIntervention
      );
    },
  },
  mounted() {
    this.initData();
    this.fillData();
  },
};

const filterByLV = (folders, parent) => folders.filter((folder)=> folder.parent === parent);

</script>

<style scoped>
  h6{
    margin: 0.5rem 0;
    font-weight: bold;
    color: #17a2b8;
  }

  #ribbon,#ribbon2,#ribbon3 {
  padding: 0 0 5px 0;
  position: absolute;
  right: 0.25em;
  top: -0.25em;
  width: 40px;
}
#ribbon{
  width: 20px;
  right: -15px;
  top: 0;
}

#ribbon2{
  top: -15px;
  right: -15px;
  width: 30px;
}

#ribbon3{
  top:-30px;
}

#ribbon2 div {
  background: #bb3a34;
  background: linear-gradient(#e74841 0%, #bb3a34 100%);
  color: #fff;
  padding: 0.15em 0;
  text-align: center;
  text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.5);
  border-radius: 2rem;
}
#ribbon div {
  background: #3492bb;
  background: linear-gradient(#41d9e729 0%, #346cbb36 100%);
  color: #17a2b8;
  padding: 0.05em 0;
  text-align: center;
  text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.5);
  border-radius: 2rem;
}

#ribbon3 div{
  background: linear-gradient(#17a2b8 0%, #17a2b8 100%);
  color: #fff;
  padding: 0.05em 0;
  text-align: center;
  text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.5);
  border-radius: 2rem;
}

#ribbon2 div:after {
  /* border-left: 20px solid #bb3a34;
  border-right: 20px solid #bb3a34; */
  border-bottom: 10px solid transparent;
  bottom: 0;
  content: '';
  height: 0;
  left: 0;
  position: absolute;
  width: 0;
}
.blockStyle {
  text-align: start;
  border-radius: 0.5rem;
  padding: 1rem;
  margin: 1rem;
  position: relative;
  box-shadow: 5px 5px 15px -3px #02363e8f;
  background: #fff;
}

.notebook{
  background: #f0f8ff;
}

.container {
  text-align: justify;
  padding: 4rem 1rem 0rem 4.5rem;
}

.inputForm {
  margin: 2rem 1rem 2rem 1rem;
  min-width: 100%;
}

.dialogActionBtns {
  display: flex;
  justify-content: space-between;
}

.userActions,
.userData {
  display: flex;
  padding: 1rem 0.5rem;
}

.userData:hover {
  box-shadow: 1px 1px 30px gray;
  border-radius: 1rem;
  background: #3bdec81f;
}

.userActions {
  border-top: 3px solid #17a2b8;
  margin: 0.5rem 0;
}

.userDataItem {
  display: flex;
  width: 100%;
  justify-content: space-between;
  text-align: start;
}

.protocolCode > div {
  background: #17a2b8;
  color: white;
}
</style>
<style>
.con-form > div {
  margin-bottom: 1.5rem;
}

.selectInput {
  max-width: none;
  width: 100%;
}

.inputLogin > div > .vs-input {
  max-width: none;
  width: 100%;
}

.inputLogin > div > .vs-select__input {
  max-width: none;
  width: 100%;
}

.inputForm > div > input {
  width: 100%;
}
.inputForm > div > .vs-select__input {
  width: 100%;
  min-width: none;
}
</style>

<style>
@media print {
  .container,
  .vs-sidebar-content,
  .vs-dialog__footer {
    display: none;
  }

  .protocolCode {
    z-index: 100 !important;
    visibility: visible !important;
    position: relative !important;
    /* display: block !important; */
    background-color: lightgray !important;
    height: 297mm !important;
    width: 211mm !important;
    position: relative !important;
    vertical-align: middle;
    padding: 0px;
    top: 0 !important;
    left: 0 !important;
    margin: auto !important;
    orphans: 0 !important;
    widows: 0 !important;
    overflow: visible !important;
    page-break-after: always;
  }
  @page {
    size: A4;
    margin: 0mm;
    orphans: 0 !important;
    widows: 0 !important;
  }
}
</style>
